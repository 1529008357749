<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>活动券</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="活动券名称">
                                <el-input v-model="search.coupon_activity_title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="上下架状态">
                                <el-select v-model="search.publish_state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="上架" :value=1></el-option>
                                    <el-option label="下架" :value=0></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="数据状态">
                                <el-select v-model="search.state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="启用" :value=1></el-option>
                                    <el-option label="禁用" :value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('coupon.library.isadd')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="toadd()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="coupon_activity_title"
                        label="活动券名称">
                </el-table-column>
                <el-table-column
                        prop="coupon_activity_qrcode"
                        label="活动二维码"
                        width="100">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.coupon_activity_qrcode)">
                            <el-avatar shape="square" :size="22" :src="scope.row.coupon_activity_qrcode"></el-avatar>
                        </viewer>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="cover"
                        label="活动券封面"
                        width="100">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.cover)">
                            <el-avatar shape="square" :size="22" :src="scope.row.cover"></el-avatar>
                        </viewer>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===1">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="publish_state"
                        label="上下架状态"
                        width="200">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.publish_state===0" type="warning">下架</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state===1" type="success">上架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="receive_sum"
                        label="已领取数量"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.receive_sum }}次</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="quota"
                        label="总数量"
                        width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.quota>0">{{ scope.row.quota }}</span>
                        <span v-else>不限制</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="limit_sum"
                        label="限领数量"
                        width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.limit_sum>0">{{ scope.row.limit_sum }}次</span>
                        <span v-else>不限制</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="range_type"
                        label="优惠券价值"
                        width="100">
                    <template slot-scope="scope">
                        <span>￥{{ scope.row.cost }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="300">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('coupon.activity.getinfo')"
                                @click="tosee(scope.row.coupon_activity_uuid)"
                                size="mini">查看
                        </el-button>
                        <el-button
                                v-if="is_auth('coupon.activity.isenable') && scope.row.state===2 "
                                @click="operation_tip(scope.row.coupon_activity_uuid,scope.row.coupon_activity_title,'isenable')"
                                type="primary" size="mini">启用
                        </el-button>
                        <el-button
                                v-if="is_auth('coupon.activity.isdisable') && scope.row.state===1 "
                                @click="operation_tip(scope.row.coupon_activity_uuid,scope.row.coupon_activity_title,'isdisable')"
                                type="danger" size="mini">禁用
                        </el-button>
                        <el-button
                                v-if="is_auth('coupon.activity.upapply') && scope.row.publish_state===0 && scope.row.state===1"
                                @click="operation_tip(scope.row.coupon_activity_uuid,scope.row.coupon_activity_title,'upapply')"
                                type="success" size="mini">上架发布
                        </el-button>
                        <el-button
                                v-if="is_auth('coupon.activity.withdraw') && scope.row.publish_state!==0 && scope.row.state===1"
                                @click="operation_tip(scope.row.coupon_activity_uuid,scope.row.coupon_activity_title,'withdraw')"
                                type="warning" size="mini">下架撤回
                        </el-button>
                        <el-button
                                v-if="is_auth('coupon.activity.isdel')"
                                @click="operation_tip (scope.row.coupon_activity_uuid,scope.row.coupon_activity_title,'isdel')"
                                type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '活动券',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                coupon_bale_title: '',
                publish_state: '',
                state: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coupon.activity.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(coupon_activity_uuid = '', coupon_bale_title = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + coupon_bale_title + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + coupon_bale_title + '】？'
            }
            // 上架
            if (operation === 'upapply') {
                tip = '上架【' + coupon_bale_title + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '下架【' + coupon_bale_title + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + coupon_bale_title + '】？'
                options = {type: 'error'}
            }
            console.log('coupon_activity_uuid',coupon_activity_uuid)
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(coupon_activity_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(coupon_activity_uuid = '', operation = '') {
            let postdata = {
                api_name: "coupon.activity." + operation,
                token: this.Tool.get_l_cache('token'),
                coupon_activity_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加
        toadd() {
            this.$router.push({path: '/coupon/activity/add'})
        },
        // 查看
        tosee(coupon_activity_uuid) {
            this.$router.push({path: '/coupon/activity/info', query: {coupon_activity_uuid}})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
